/**
 * Page transitions actions
 */

export const startTransition = () => {
  return {
    type: `START_TRANSITION`,
    transition: true,
  }
}

export const endTransition = () => {
  return {
    type: `END_TRANSITION`,
    transition: false,
  }
}

export const setTransitionType = (transitionType, transitionTimeout = 750) => {
  return {
    type: `SET_TRANSITION_TYPE`,
    transitionType: transitionType,
    transitionTimeout: transitionTimeout,
  }
}

export const resetTransition = () => {
  return {
    type: `RESET_TRANSITION`,
    transition: false,
  }
}

/**
 * Action for opening nav-main elements
 */
export const openNav = () => {
  return {
    type: `OPEN_NAV`,
    closed: false,
  }
}

/**
 * Action for closing nav-main elements
 */
export const closeNav = () => {
  return {
    type: `CLOSE_NAV`,
    closed: true,
  }
}

/**
 * Action for inverting site header
 */
export const invertHeader = () => {
  return {
    type: `INVERT_HEADER`,
    inverted: true,
  }
}

/**
 * Action for reseting site header
 */
export const resetHeader = () => {
  return {
    type: `RESET_HEADER`,
    inverted: false,
  }
}

/**
 * Action for hiding the header
 */
export const hideHeader = () => {
  return {
    type: `HIDE_HEADER`,
    hidden: true,
  }
}

/**
 * Action for hiding the header
 */
export const showHeader = () => {
  return {
    type: `SHOW_HEADER`,
    hidden: true,
  }
}

/**
 * Set current slider index
 */
export const sliderSetIndex = index => {
  return {
    type: `SET_CURRENT_SLIDER_INDEX`,
    index: index,
  }
}

/**
 * Action for increasing a slide opacity
 */
export const incrementSlideOpacity = opacity => {
  return {
    type: `INCREMENT_SLIDE_OPACITY`,
    opacity: opacity,
  }
}

/**
 * Action for storing current search string
 */
export const setSearchString = (
  searchString,
  hasSearchResults,
  isSearchPage
) => {
  return {
    type: `SET_SEARCH_STRING`,
    searchString: searchString,
    hasSearchResults: hasSearchResults,
    isSearchPage: isSearchPage,
  }
}

/**
 * Action for storing current search string
 */
export const resetSearch = isSearchPage => {
  return {
    type: `RESET_SEARCH`,
  }
}

/**
 * Action for storing current search string
 */
export const initialSearch = () => {
  return {
    type: `INITIAL_SEARCH`,
  }
}
