const breakpoints = {
  mobile: 320,
  mobileLandscape: 576,
  tablet: 992,
  tabletLandscape: 1024,
  desktop: 1168,
  desktopLarge: 1400,
  desktopWide: 1920,
}

export const isMobile = (currentBreakpoint = null, target) =>
  currentBreakpoint
    ? breakpoints[currentBreakpoint] < breakpoints[target]
    : false

export default breakpoints
