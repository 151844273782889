import React, { Component } from "react"

class Close extends Component {
  render() {
    return (
      <svg
        className={this.props.className}
        width="15px"
        height="15px"
        viewBox="0 0 15 15"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="square"
        >
          <g id="Group-2" stroke={`${this.props.color}`}>
            <path
              d="M-1.56208192,7.528422 L16.9843201,7.528422"
              id="Line-Copy-2"
              transform="translate(7.711119, 7.528422) rotate(-315.000000) translate(-7.711119, -7.528422) "
            />
            <path
              d="M7.76758565,-1.58116432 L7.76758565,16.7629133"
              id="Line-Copy-2"
              transform="translate(8.054682, 7.590874) rotate(-315.000000) translate(-8.054682, -7.590874) "
            />
          </g>
        </g>
      </svg>
    )
  }
}

Close.defaultProps = {
  className: `icn__close`,
  color: `#000000`,
}

export default Close
